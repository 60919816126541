
import { Component, Vue } from 'vue-property-decorator';
import { KEY } from '@/inversify.keys';
import { inject } from '@/inversify';
import type UserService from '@/modules/user/user.service';

@Component
export default class TabsHotel extends Vue {
    @inject(KEY.UserService) private userService!: UserService;

    get tabs() {
        const names = [
            'room-type-mapping',
            'alerts-notifications',
            'compset-settings',
            'email-reports',
            'scheduled-reports',
            'rvs-weight',
        ];

        if (this.userService.isAdmin) {
            names.push('net-calculation');
        }

        return names;
    }

    get pathName() {
        if (this.userService.isViewAsHotel) {
            return 'hotel.settings.hotel';
        }

        return 'cluster.settings.hotel';
    }
}
