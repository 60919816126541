import { render, staticRenderFns } from "./tabs-hotel.vue?vue&type=template&id=02cf3988&scoped=true&"
import script from "./tabs-hotel.vue?vue&type=script&lang=ts&"
export * from "./tabs-hotel.vue?vue&type=script&lang=ts&"
import style0 from "./tabs-hotel.vue?vue&type=style&index=0&id=02cf3988&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02cf3988",
  null
  
)

export default component.exports